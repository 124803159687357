import React from 'react';

const DateTimeInput = ({ id, label, value, onChange, error, required=true }) => {
  return (
    <div className="mb-4">
      <div className='flex'>
        <label htmlFor={id} className="block text-sm font-medium text-gray-700">
          {label}
        </label>
        {required ? (
          <label className='text-red-500'> *</label>
        ) : null}
      </div>
      
      <input
        id={id}
        type="datetime-local"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className={`mt-1 w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 border-gray-300`}
        required
      />

      {error && (
        <p className="text-red-500 text-sm mt-1">{error}</p>
      )}
    </div>
  );
};

export default DateTimeInput;