import React from 'react';

const Label = ({ text, required=false }) => {
  return (
    <div className='flex'>
      <label className="block text-sm font-medium text-gray-700">
        {text}
      </label>
      {required ? (
        <label className='text-red-500'> *</label>
      ) : null}
    </div>
  );
};

export default Label;