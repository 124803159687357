import React from 'react';

function PrimaryButton({type, value, loading, bgColor="bg-primary"}) {
  return (
    <button type={type} className={"px-4 py-2 text-white rounded hover:bg-secondary "+bgColor} >
      <div className='flex items-center'>
        {loading ? (
          <div className="animate-spin rounded-full border-t-2 border-b-2 border-white h-4 w-4 mr-2"></div>
        ) : null }
        {value}
      </div>

    </button>
  );
}

export default PrimaryButton;