import React, { useState, useEffect } from 'react';
import { app } from '../firebase'; // Importez votre configuration Firebase
import { getFirestore, collection, onSnapshot, query, where, orderBy, updateDoc, doc } from 'firebase/firestore'; 
import Loader from '../components/Loader';
import { isCoownership, isProvider, useUser } from '../contexts/UserContext';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, DotGroup } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import styled from "styled-components";
import PrimaryButton from '../components/buttons/PrimaryButton';
import DeleteModal from '../components/modals/DeleteModal';
import { dateFromTimestampToString, timeFromTimestampToString } from '../librairies/Dates';
import AddEvent from './AddEvent';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import '../assets/css/Calendar.css'
import moment from 'moment';
import { TEXT_SHARE_EVENT } from '../helpers/Text';
import ShareButton from '../components/buttons/ShareButton';


const db = getFirestore(app);

const CalendarEvents = () => {

  const [events, setEvents] = useState([]); 
  const [loader, setLoader] = useState(true);
  const [showAddEvent, setShowAddEvent] = useState(false); 
  const [showArchiveModal, setShowArchiveModal] = useState(false); 
  const [elementToArchive, setElementToArchive] = useState(null); 
  const [dateCalendar, setDateCalendar] = useState(new Date()); 
  const [dateBegining, setDateBegining] = useState(new Date()); 
  const [dateEnd, setDateEnd] = useState(() => {
    const nextDay = new Date(dateBegining); // Clone `dateBegining`
    nextDay.setDate(nextDay.getDate() + 1); // Incrémente le jour de 1
    return nextDay;
  });

  const [eventsDate, setEventsDate] = useState([]);

  //contexte de l'utilisateur connecté
  const user = useUser();  

  useEffect(() => {

    const queryPlanning = query(collection(db, user.user.userData.lastCoownershipLoaded.path+"/events"), 
      where('status', '==', 'accepted')); 
      
    const unsubPlanning = onSnapshot(queryPlanning, (snapshot) => {
      if(snapshot.docs.length > 0){
        setEventsDate([]); //on remet les dates à vide pour insérer toutes les dates
        for(let i=0; i < snapshot.docs.length; ++i){ //on boucle sur l'ensemble des events activés 
      
          // Convertir le timestamp en millisecondes (Firestore utilise des millisecondes)
          const eventDate = new Date(snapshot.docs[i].data().dateBegining.seconds * 1000);

          setEventsDate(dates => [...dates, moment(eventDate).format('YYYY-MM-DD')]);
        }
      }else{
        setEventsDate([]); //il n'y a pas d'events
      }
    
    }, error => { console.log(error) });
  
    return () => {
      console.log("unsubscribe dates event");
      unsubPlanning();
    }
  }, []); 

  useEffect(() => {
    const collectionRef = query(collection(db, user.user.userData.lastCoownershipLoaded.path+"/events"), 
      where('status', '==', 'accepted'),
      where('dateBegining', '>=', dateBegining), //'2024-10-16T00:00:00'
      where('dateBegining', '<', dateEnd)) //'2024-10-17T00:00:00'

    const unsubscribe = onSnapshot(collectionRef, (snapshot) => {

      if(snapshot.docs.length > 0){
        
        let arr = [];

        snapshot.docs.map((event) => {
          let obj = {
            id: event.id, 
            idCreator: event.data().idCreator.path,
            title: event.data().title, 
            description: event.data().description, 
            firstname: event.data().firstnameCreator,
            lastname: event.data().lastnameCreator,
            dateBegining: event.data().dateBegining.seconds,
            dateEnd: event.data().dateEnd.seconds,
            status: event.data().status,
            typeMedia: event.data().typeMedia,
            linkMedia: event.data().linkMedia,
          }

          arr.push(obj);

        });
        
        setEvents(arr);
      }else{
        setEvents([]); 
      }

      setLoader(false);
    });

    return () => {
      console.log("unsubscribe events");
      unsubscribe();
    }
  }, [dateBegining]); 


  useEffect(() => {
    if(elementToArchive !== null){
      setShowArchiveModal(true); 
    }
  }, [elementToArchive]);

  useEffect(() => {
    if(showArchiveModal === false){
      setElementToArchive(null);
    }
  }, [showArchiveModal]);

  /**
  * On archive un billboard
  */
  const archiveEvent = async () => {
    if(elementToArchive !== null){
      const docRef = doc(db, user.user.userData.lastCoownershipLoaded.path+"/events/"+elementToArchive);
      await updateDoc(docRef, {
        status: "archived"
      });

      setShowArchiveModal(false);

    }
  }

  const changeDate = (value) => {

    // Crée un nouvel objet Date pour la date sélectionnée
    const selected = new Date(value.getFullYear(), value.getMonth(), value.getDate(), 6, 0, 0);

    // Crée une nouvelle date pour le jour suivant
    const nextDate = new Date(selected);
    nextDate.setDate(selected.getDate() + 1);

    setDateBegining(selected); 
    setDateEnd(nextDate); 
  }

  const isSpecialDate = (date) => {
    // Convertit la date en chaîne AAAA-MM-JJ
    const dateString = moment(date).format('YYYY-MM-DD');
    return eventsDate.includes(dateString);
  };

  const tileStyles = {
    backgroundColor: '#E49680',
    color: 'white',
    borderRadius: '50%',
    height: '32px',
    width:'32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  return (
    <div>      

      <div className='flex gap-4 justify-end'>
        <ShareButton text={TEXT_SHARE_EVENT(user.user.userData.codeOwner)} designBox={false} />

        <a href="#" onClick={() => setShowAddEvent(true)} className="justify-end flex mb-4">
          <PrimaryButton type={"button"} value={"Ajouter un événement le "+dateFromTimestampToString(Math.floor(dateBegining.getTime()/1000))} loading={false} />
        </a>
      </div>

      <div className='m-auto border w-80 my-10'>
        <Calendar onChange={setDateCalendar} value={dateCalendar} onClickDay={(value, event) => changeDate(value)} 
          tileClassName={({ date, view }) => {
            if (view === 'month') {
              const isSpecial = isSpecialDate(date) ? 'special-date' : '';
              const isSelected = date.toDateString() === dateCalendar.toDateString() ? 'selected-date' : '';
              return `${isSpecial} ${isSelected}`.trim(); // Ajoute les classes si elles existent
            }
            return null;
          }}
          tileContent={({ date, view }) =>
            view === 'month' && isSpecialDate(date) ? (
              <div style={tileStyles}>{date.getDate()}</div>
            ) : null
          }
        />
      </div>
      
      {loader ? (
        <Loader />
      ) : (
        events.length > 0 ? (
          <CarouselProvider
          naturalSlideWidth={100}
          naturalSlideHeight={60}
          visibleSlides={1}
          totalSlides={events.length}
        >
          <Slider >
            {events.map((event, key) => {
              return(
                <Slide index={key} key={key}>
                  <div className='border rounded-8 shadow'>
                    <div className='flex'>
                      <h3 className='text-center text-lg p-4 font-bold flex-1'>{event.title}</h3>
                      {isCoownership(user.user) || (isProvider(user.user) && event.idCreator === "users/"+user.user.user.uid) ? (
                        <a href={"#"} onClick={() => setElementToArchive(event.id)} className=' p-4'>
                          <p className='text-red-500 font-bold'>Archiver</p>
                        </a>
                      ) : null }
                    </div>

                    {event.typeMedia !== undefined && event.typeMedia !== null ? (
                      event.typeMedia === "image" ? (
                        <a href={event.linkMedia} target='_blank'>
                          <img src={event.linkMedia} alt={event.title} className='p-4 rounded-xl border m-auto object-contain h-64 w-64'/>
                        </a>
              
                      ) : (
                        <a href={event.linkMedia} target='_blank'>
                          <p className='text-blue-600 p-4'>Afficher le pdf</p>
                        </a>
                      )
                    ) : (
                      null
                    )}
                    <p className='text-left px-4 py-2 whitespace-pre-wrap'>{event.description}</p>
                    <p className='text-left px-4 py-2'>{"Prévu le "+ dateFromTimestampToString(event.dateBegining) +" de " + timeFromTimestampToString(event.dateBegining) + " à " + timeFromTimestampToString(event.dateEnd)}</p>
                    
                  </div>
                
                </Slide>
              )
            })}
          </Slider>
          <Wrapper>
            <div className="controls">
              <ButtonBack className="btn-arrow ">
                <p className='text-cyan-600'>Précédent</p>
              </ButtonBack>
              <DotGroup className="dot-group" />
              <ButtonNext className="btn-arrow">
                <p className='text-cyan-600'>Suivant</p>
              </ButtonNext>
            </div>
          </Wrapper>

        </CarouselProvider>
        ) : (
          dateBegining ? (
            <p className='text-center'>Aucun événement prévu pour le {dateFromTimestampToString(Math.floor(dateBegining.getTime()/1000))}</p>
          ) : null
        )
      )}

      {showAddEvent ? (
        <AddEvent onClose={() => setShowAddEvent(false)} date={dateBegining}/>
      ) : null }

      {showArchiveModal ? (
        <DeleteModal closeAction={() => setShowArchiveModal(false)} confirmAction={() => archiveEvent()} type={"archive"}/>
      ) : null }
    </div>
  );
};


const Wrapper = styled.div`
  .controls {
    display: flex;
    align-items: center;
    justify-content: center;

    .btn-arrow {
      border: none;
      background: none;
      padding: 11px 20px;
    }

    .reverse-arrow {
      transform: rotateY(180deg);
    }

    .dot-group {
      display: flex;
      align-items: center;
      justify-content: center;

      .carousel__dot {
        width: 8px;
        height: 8px;
        border: none;
        border-radius: 50%;
        margin: 0 4px;
        padding: 0;
        background-color: #c3c4ca;
      }

      /* This class is found in DotGroup from pure-react-carousel */
      /* We need to override it to add our styles */
      .carousel__dot--selected {
        width: 16px;
        height: 8px;
        border-radius: 10px;
        background-color: #E49680;
        transition: background 0.4s ease;
      }
    }
  }
`;

export default CalendarEvents;

