import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

const storage = getStorage();


export const handleUpload = async (folder, file) => {
  if (!file) return null;

  // Create a child reference
  const uniqueFileName = `${crypto.randomUUID()}_${file.name}`;
  const storageRef = ref(storage, folder+`${uniqueFileName}`);

  let urlFile = null; 

  return uploadBytes(storageRef, file).then(async (snapshot) => {
    console.log('Uploaded a blob or file!');

    console.log(snapshot);
    urlFile = await getDownloadURL(snapshot.ref);

    console.log('URL de téléchargement du file :', urlFile);

    return urlFile; 

  }).catch((err) => {
    console.log(err);
    return null;
  });

};