import React, { useState, useEffect } from 'react';
import { app } from '../firebase'; // Importez votre configuration Firebase
import { getFirestore, collection, doc, updateDoc, onSnapshot } from 'firebase/firestore'; 
import Loader from '../components/Loader';
import { isAdmin, useUser } from '../contexts/UserContext';
import PrimaryButton from '../components/buttons/PrimaryButton';
import AddCoownership from './AddCoownership';
import LinkCoownership from './LinkCoownership';
import UnlinkCoownership from './UnlinkCoownership';
//import DeleteModal from '../components/modals/DeleteModal';

const db = getFirestore(app);

const ListCoownerships = () => {

  const [coownerships, setCoownerships] = useState([]); 
  const [loader, setLoader] = useState(true);
  
  const [showAddCoownership, setShowAddCoownership] = useState(false); 
  const [showLinkCoownership, setShowLinkCoownership] = useState(false);
  const [showUnlinkCoownership, setShowUnlinkCoownership] = useState(false);
  //const [showUpdateCoownership, setShowUpdateCoownership] = useState(false); 
  //const [showDeleteModal, setShowDeleteModal] = useState(false); 
  //const [elementToDelete, setElementToDelete] = useState(null); 
  //const [elementToUpdate, setElementToUpdate] = useState(null);

  //contexte de l'utilisateur connecté
  const user = useUser();


  useEffect(() => {
    if(isAdmin(user.user)){
      const collectionRef = collection(db, "coownership");

      const unsubscribe = onSnapshot(collectionRef, (snapshot) => {
  
        if(snapshot.docs.length > 0){
          
          let arr = [];
  
          snapshot.docs.map((coownership) => {
            let obj = {
              id: coownership.id, 
              name: coownership.data().name, 
              nameSyndic: coownership.data().nameSyndic, 
              email: coownership.data().email,
              emailSyndic: coownership.data().mailSyndic,
              phone: coownership.data().phone,
              phoneSyndic: coownership.data().phoneSyndic,
              image: coownership.data().image,
              address: coownership.data().address,
              zip: coownership.data().zip,
              city:coownership.data().city,
              codeOwner: coownership.data().codeOwner,
              codeProvider: coownership.data().codeProvider,
              codeTenant:coownership.data().codeTenant,
              status:coownership.data().status,
              totalTantieme:coownership.data().totalTantieme,
              subscription:coownership.data().subscription,
            }
  
            arr.push(obj);
  
          });
          
          setCoownerships(arr);
        }
  
        setLoader(false);
      });
  
      return () => {
        console.log("unsubscribe coownerships");
        unsubscribe();
      }
    }
  }, []); 

  /*
  useEffect(() => {
    if(elementToDelete != null){
      setShowDeleteModal(true); 
    }
  }, [elementToDelete]);

  useEffect(() => {
    if(elementToUpdate != null){
      setShowUpdateProvider(true); 
    }
  }, [elementToUpdate]);


  useEffect(() => {
    if(showDeleteModal === false){
      setElementToDelete(null);
    }
  }, [showDeleteModal]);


  useEffect(() => {
    if(showUpdateProvider === false){
      setElementToUpdate(null);
    }
  }, [showUpdateProvider]);
  */


  /**
  * Permet de modifier le statut (activé ou désactivé) pour une copropriétée
  * @param {*} userId 
  * @param {*} integrate 
  */
  const setCoownershipStatus = async (coownershipId, status) => {
    if(isAdmin(user.user)){
      const docRef = doc(db, "coownership/"+coownershipId);

      await updateDoc(docRef, {
        status: status
      });
    }
  }

  /**
   * Pemet de modifier la subscription au mode premium pour une copropriété 
   * @param {*} coownershipId 
   * @param {*} activate 
   */
  const setCoownershipSubscription = async (coownershipId, activate) => {
    if(isAdmin(user.user)){
      const docRef = doc(db, "coownership/"+coownershipId);

      await updateDoc(docRef, {
        subscription: activate
      });
    }
  }
  
  

  return (
    <div>

      <div className='flex gap-4'>
        <a href="#" onClick={() => setShowLinkCoownership(true)} className="justify-end flex mb-4">
          <PrimaryButton type={"button"} value={"Lier une copropriété"} loading={false} />
        </a>

        <a href="#" onClick={() => setShowAddCoownership(true)} className="justify-end flex mb-4">
          <PrimaryButton type={"button"} value={"Ajouter une copropriété"} loading={false} />
        </a>

        <a href="#" onClick={() => setShowUnlinkCoownership(true)} className="justify-end flex mb-4">
          <PrimaryButton type={"button"} value={"Délier une copropriété"} loading={false} bgColor='bg-red-500' />
        </a>
      </div>



      
      {loader ? (
        <Loader />
      ) : (
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                    Nom
                </th>
                <th scope="col" className="px-6 py-3">
                    Adresse
                </th>
                <th scope="col" className="px-6 py-3">
                    Email
                </th>
                <th scope="col" className="px-6 py-3">
                    Nom du syndic
                </th>
                <th scope="col" className="px-6 py-3">
                    Email du syndic
                </th>
                <th scope="col" className="px-6 py-3">
                    Téléphone du syndic
                </th>
                <th scope="col" className="px-6 py-3">
                    Code propriétaires
                </th>
                <th scope="col" className="px-6 py-3">
                    Code résidents
                </th>
                <th scope="col" className="px-6 py-3">
                    Code prestataires
                </th>
                <th scope="col" className="px-6 py-3">
                    Nom
                </th>
                <th scope="col" className="px-6 py-3">
                    Action
                </th>
                <th scope="col" className="px-6 py-3">
                    Service premium
                </th>
              </tr>
            </thead>
            <tbody>
              {coownerships.map((coownership, key) => {
                return (
                  <tr key={key} className={key % 2 === 0 ? "bg-white border-b dark:bg-gray-900 dark:border-gray-700" : "border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700"}>
                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      {coownership.name}
                    </th>
                    <td className="px-6 py-4">
                      {coownership.address+" "+(coownership?.zip ? coownership.zip : "") +" "+(coownership?.city ? coownership.city : "")}
                    </td>
                    <td className="px-6 py-4">
                      {coownership.email}
                    </td>
                    <td className="px-6 py-4">
                      {coownership.nameSyndic}
                    </td>
                    <td className="px-6 py-4">
                      {coownership.emailSyndic}
                    </td>
                    <td className="px-6 py-4">
                      {coownership.phoneSyndic}
                    </td>
                    <td className="px-6 py-4">
                      {coownership.codeOwner}
                    </td>
                    <td className="px-6 py-4">
                      {coownership.codeTenant}
                    </td>
                    <td className="px-6 py-4">
                      {coownership.codeProvider}
                    </td>
                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      {coownership.name}
                    </th>
                    <td className="px-6 py-4">
                      <div className='flex gap-4'>
                        <a href="#" onClick={() => setCoownershipStatus(coownership.id, coownership.status === "activated" ? "desactivated" : "activated")} 
                          className={coownership.status === "activated" ? "font-medium hover:underline text-red-600 dark:text-red-500" : "font-medium hover:underline text-blue-600 dark:text-blue-500"}>{coownership.status === "activated" ? "Désactiver la copropriété" : "Activer la copropriété"}</a>
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <div className='flex gap-4'>
                        <a href="#" onClick={() => setCoownershipSubscription(coownership.id, coownership.subscription ? false : true)} 
                          className={coownership.subscription ? "font-medium hover:underline text-red-600 dark:text-red-500" : "font-medium hover:underline text-blue-600 dark:text-blue-500"}>{coownership.subscription ? "Désactiver le service premium" : "Activer le service premium"}</a>
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      )}

      {showAddCoownership ? (
        <AddCoownership onClose={() => setShowAddCoownership(false)}/>
      ) : null }


      {showLinkCoownership ? (
        <LinkCoownership onClose={() => setShowLinkCoownership(false)}/>
      ) : null }

      {showUnlinkCoownership ? (
        <UnlinkCoownership onClose={() => setShowUnlinkCoownership(false)}/>
      ) : null }

    </div>
  );
};

export default ListCoownerships;
