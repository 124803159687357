export const TEXT_SHARE_COOWNERSHIP_CODE = (code) => {
  return `Bonjour à tous,

L'outil Trustymo est utilisé sur votre résidence pour faciliter la communication entre prestataires, gestionnaires et copropriétaires.
Pour rester informé des actualités de votre immeuble, veuillez télécharger l'application sur votre téléphone mobile.
Créez votre compte et renseignez le code immeuble ${code}

📲 App Store : https://apps.apple.com/fr/app/trustymo/id6468989373  
📲 Play Store : https://play.google.com/store/apps/details?id=com.trustymo&hl=fr

Les 3 principales fonctionnalités:
- sondage interactif pour faciliter la prise de décision en impliquant les résidents rapidement ou planifier des visites.
- un affichage virtuel en temps réel pour une information rapide 
- un calendrier numérique pour une gestion centralisée des prestataires sous contrat afin d'assurer un meilleur suivi avec tracabilite des interventions et transparence auprès de tous.

Nous restons à votre disposition.

Cordialement`
}


export const TEXT_SHARE_COOWNERSHIP_CODE_PROVIDERS = (coownershipName, code) => {
  return `La copropriété ${coownershipName} à mis en place l'application TRUSTYMO qui permet de fluidifier la communication entre prestataires, copropriétaires et gestionnaires. 


En tant que prestataire, nous vous invitons a créer un compte pour valoriser vos interventions.

Il vous faudra dans un 1er temps télécharger l'application TRUSTYMO sur votre téléphone mobile avec le code prestataire ${code}

📲 App Store : https://apps.apple.com/fr/app/trustymo/id6468989373  
📲 Play Store : https://play.google.com/store/apps/details?id=com.trustymo&hl=fr

Une fois ce compte crée, ou si vous etes deja en possession de l'outil, vous pourrez aller dans l'onglet "paramètre" de l'application puis "Gérer mes copropriétés" et rentrer les autres codes prestataires transmis.

Ensuite, vous pourrez vous connecter sur un ordinateur pour avoir la gestion back office sur le lien suivant avec les mêmes codes d'accès que ceux de l'application mobile :
https://app.trustymo.com

Les 3 principales fonctionnalités:
- sondage interactif pour faciliter la prise de décision en impliquant les résidents rapidement ou planifier des visites.
- un affichage virtuel en temps réel pour une information rapide 
- un calendrier numérique pour une gestion centralisée des prestataires sous contrat afin d'assurer un meilleur suivi avec tracabilite des interventions et transparence auprès de tous.

Nous restons bien évidemment à votre disposition`
}


export const TEXT_SHARE_BILLBOARD = (code) => {
  return `Bonjour,

Un nouvel affichage à été réalisé sur trustymo par votre gestionnaire.
Pour le consulter veuillez télécharger l'application TRUSTYMO sur votre mobile.

📲 App Store : https://apps.apple.com/fr/app/trustymo/id6468989373  
📲 Play Store : https://play.google.com/store/apps/details?id=com.trustymo&hl=fr

Trustymo vous pemettra de rester informé des actualités de votre immeuble en facilitant la communication entre prestataires, gestionnaires et copropriétaires. 

Créez votre compte et renseignez le code propriétaire ${code} pour recevoir les notifications des futurs actualités.

Cordialement`
}


export const TEXT_SHARE_EVENT = (code) => {
  return `Bonjour,

Un nouvel événement à été réalisé sur trustymo par votre gestionnaire.
Pour le consulter veuillez télécharger l'application TRUSTYMO sur votre mobile.

📲 App Store : https://apps.apple.com/fr/app/trustymo/id6468989373  
📲 Play Store : https://play.google.com/store/apps/details?id=com.trustymo&hl=fr

Trustymo vous pemettra de rester informé des actualités de votre immeuble en facilitant la communication entre prestataires, gestionnaires et copropriétaires. 

Créez votre compte et renseignez le code propriétaire ${code} pour recevoir les notifications des futurs actualités.

Cordialement`
}