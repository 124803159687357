import React from "react";
import PrimaryButton from "./PrimaryButton";

/**
 * designBox permet de savoir si on doit gérer l'affichage pour les "box" des codes immeubles dans 
 * les infos de la résidence
 * @param {*} param0 
 * @returns 
 */
const ShareButton = ({ text, designBox=true }) => {
  const handleShare = () => {
    const subject = "Téléchargez l'application Trustymo";

    // Création du lien mailto
    const mailtoLink = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(text)}`;

    // Ouvre l'application de messagerie
    window.location.href = mailtoLink;
  };

  return (
    designBox ? (
      <div className="flex justify-center mt-4">
        <button
          onClick={handleShare}
          className="bg-white text-primary font-bold py-2 px-4 rounded-lg shadow-lg hover:bg-gray-200 transition"
        >
          Partager
        </button>
      </div>
    ) : (
      <a href="#" onClick={handleShare} className="justify-end flex mb-4">
        <PrimaryButton type={"button"} value={"Partager"} loading={false} />
      </a>
    )
  );
};

export default ShareButton;