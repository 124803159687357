import { collection, doc, getFirestore, addDoc, updateDoc, query, where, orderBy, getDocs, writeBatch } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import PrimaryButton from '../components/buttons/PrimaryButton';
import { app } from '../firebase'; // Importez votre configuration Firebase
import { useUser } from '../contexts/UserContext';
import { fetchData } from '../librairies/Firestore';

const db = getFirestore(app);

function UnlinkCoownership({onClose}) {

  //contexte de l'utilisateur connecté
  const user = useUser();

  const [loader, setLoader] = useState(false);
  const [error, setError] = useState("");

  //coownership
  const [coownershipsAccount, setCoownershipsAccount] = useState([]); 
  const [selectedCoownership, setSelectedCoownership] = useState(null);

  //comptes gestionnaires - syndic
  const [existAccount, setExistAccount] = useState(false); 
  const [syndicAccount, setSyndicAccount] = useState([]); 
  const [selectedSyndicAccount, setSelectedSyndicAccount] = useState(null);

  useEffect(() => {
    getSyndicAccounts();
    getCoownerships();
  }, []); 


  const updateCoownershipArray = async () => {
    const docRef = doc(db, 'users', selectedSyndicAccount);
    let user = await fetchData(docRef); 

    if(user !== null){

      let coownershipArray = user.coownershipId; 

      let newObj = {coownershipReference: doc(db, 'coownership', selectedCoownership)}; 

      coownershipArray.push(newObj); 

      //on envoie les données sur firestore 
      await updateDoc(docRef, {
        coownershipId: coownershipArray
      });
    }
  }

  /**
   * récupère l'ensemble des comptes syndics existants
   */
  const getSyndicAccounts = async () => {
    const collectionRef = query(collection(db, "users"), where("status", "==", "copropriete"), orderBy("email"));

    let data = await getDocs(collectionRef); 
    
    if(data !== null){
      setSyndicAccount(data.docs); 
    }
  }

  /**
   * Récupère l'ensemble des copropriétés
   */
  const getCoownerships = async () => {
    const collectionRef = query(collection(db, "coownership"), orderBy("name"));

    let data = await getDocs(collectionRef); 
    
    if(data !== null){
      setCoownershipsAccount(data.docs); 
    }
  }

  /**
   * On submit le formulaire
   * @param {*} e 
   */
  const handleSubmit = async (e)  => {
    e.preventDefault();
    
    try{
      setLoader(true);
      
      if(selectedCoownership !== null){

        const coownershipRef = doc(db, "coownership/"+selectedCoownership)

        if(syndicAccount.length <= 0){
          return; 
        }

        const batch = writeBatch(db);

        //todo on bloucle sur les comptes syndics 
        syndicAccount.map((syndic) => {
          const syndicData = syndic.data();
          if (!syndicData.coownershipId || syndicData.coownershipId.length === 0) return;
        
          const updatedCoownerships = syndicData.coownershipId.filter(
            (coownership) => coownership.coownershipReference.path !== coownershipRef.path
          );

          if (updatedCoownerships.length !== syndicData.coownershipId.length) {
            //console.log("Ce syndic contenait la copro : " + syndicData.email);
        
            const syndicRef = doc(db, "users", syndic.id);
            
            // Préparer les mises à jour
            let updateData = { coownershipId: updatedCoownerships };
        
            // Vérifier si on doit mettre à jour lastCoownershipLoaded
            if (syndicData.lastCoownershipLoaded.path === coownershipRef.path) {
              updateData.lastCoownershipLoaded = updatedCoownerships.length > 0 
                ? updatedCoownerships[0].coownershipReference // Prendre la première référence restante
                : null; // S'il n'y a plus rien, mettre null
            }
        
            batch.update(syndicRef, updateData);
          }
        })
  
        await batch.commit();

        setLoader(false);

        //on close la popup
        onClose(); 
      }else{
        setError("Aucune copropriété n'a été sélectionnée");
        setLoader(false);
        return; 
      }
    } catch(err){
      console.log(err)
      setError("Une erreur est survenue, impossible de délier la copropriété");
      setLoader(false);
    }
  };


  return (
    <div id="authentication-modal" tabIndex="-1" aria-hidden="true" className="mx-auto w-full fixed inset-0 flex items-center justify-center overflow-auto">
      <div className="relative w-full max-w-md max-h-full">
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <button type="button" onClick={onClose} className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="authentication-modal">
            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
          <div className="px-6 py-6 lg:px-8">
            <h3 className="mb-4 text-xl font-medium text-gray-900 dark:text-white">Délier une copropriété</h3>

            <form onSubmit={handleSubmit}>

              <div className='mb-6'>
                <div className='text-lg text-primary'>La copropriété</div>
                <div className='border-b border-gray mt-0.5 mb-2'></div>

                <div>
                  <label for="coownerships" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Selectionnez une copropriété</label>
                  <select id="coownerships" onChange={(e) => setSelectedCoownership(e.target.value)} 
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                    <option selected>Sélectionnez une copropriété</option>
                    {coownershipsAccount.length > 0 ? (
                      coownershipsAccount.map((coownership) => {
                        return (
                          <option key={coownership.id} value={coownership.id}>{coownership.data().name}</option>
                        )
                      })
                    ) : null }
                  </select>
                </div>
              </div>

              <div className="text-center">
                <PrimaryButton type={"submit"} value={"Délier"} loading={loader} />
              </div>

              {error !== "" ? (
                <p className='justify-center flex my-1 text-red-500'>{error}</p>
              ) : null}
              
            </form>

          </div>
        </div>
      </div>
    </div> 
  );
}

export default UnlinkCoownership;